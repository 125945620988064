<template>
  <div class="choir">
    <div class="mixer">
      <div class="mixer-video">
        <Playable :caption="'Tune of the Hamlets'"
        :artist="'Daniël Siegersma'" @triggered="togglePlayback">
          <video src="../public/choir/video.mp4" ref="video" loop></video>
        </Playable>
        <audio src="../public/choir/1herman.mp3" ref="twents" loop></audio>
        <audio src="../public/choir/2janny.mp3" ref="drents" loop></audio>
        <audio src="../public/choir/3douwe.mp3" ref="urkers" loop></audio>
        <audio src="../public/choir/4reitze.mp3" ref="stellingwerfs" loop></audio>
        <audio src="../public/choir/5trijnie.mp3" ref="gronings" loop></audio>
        <audio src="../public/choir/piano.mp3" ref="piano" loop></audio>
      </div>
      <div class="mixer-buttons">
        <div class="mixer-button" @mousedown="startMixer" @mouseup="stopMixer">
          <circle-slider
              :progress-width="5"
              :knob-radius="10"
              circle-color="#000000"
              progress-color="#d1d1d1"
              knob-color="#d1d1d1"
               v-model="twents"></circle-slider>
          <label>Twents</label>
        </div>
        <div class="mixer-button" @mousedown="startMixer" @mouseup="stopMixer">
          <circle-slider :progress-width="5"
          :knob-radius="10"
          circle-color="#000000"
          progress-color="#d1d1d1"
          knob-color="#d1d1d1" v-model="drents"></circle-slider>
          <label>Drents</label>
        </div>
        <div class="mixer-button" @mousedown="startMixer" @mouseup="stopMixer">
          <circle-slider :progress-width="5"
          :knob-radius="10"
          circle-color="#000000"
          progress-color="#d1d1d1"
          knob-color="#d1d1d1" v-model="urkers"></circle-slider>
          <label>Urkers</label>
        </div>
        <div class="mixer-button" @mousedown="startMixer" @mouseup="stopMixer">
          <circle-slider :progress-width="5"
          :knob-radius="10"
          circle-color="#000000"
          progress-color="#d1d1d1"
          knob-color="#d1d1d1" v-model="stellingwerfs"></circle-slider>
          <label>Stellingwerfs</label>
        </div>
        <div class="mixer-button" @mousedown="startMixer" @mouseup="stopMixer">
          <circle-slider :progress-width="5"
          :knob-radius="10"
          circle-color="#000000"
          progress-color="#d1d1d1"
          knob-color="#d1d1d1" v-model="gronings"></circle-slider>
          <label>Gronings</label>
        </div>
        <div class="mixer-button" @mousedown="startMixer" @mouseup="stopMixer">
          <circle-slider :progress-width="5"
          :knob-radius="10"
          circle-color="#000000"
          progress-color="#d1d1d1"
          knob-color="#d1d1d1" v-model="piano"></circle-slider>
          <label>Piano</label>
        </div>
      </div>
    </div>
    <div class="lyrics">
      <p>
        <span class="dialect" :class="dialect === 1 ? 'active' : ''" @click="setDialect(1)">Twents</span>
        <span class="dialect" :class="dialect === 2 ? 'active' : ''" @click="setDialect(2)">Drents</span>
        <span class="dialect" :class="dialect === 3 ? 'active' : ''" @click="setDialect(3)">Urkers</span>
        <span class="dialect" :class="dialect === 4 ? 'active' : ''" @click="setDialect(4)">Stellingwerfs</span>
        <span class="dialect" :class="dialect === 0 ? 'active' : ''" @click="setDialect(0)">Gronings</span>
        <span class="dialect" :class="dialect === 6 ? 'active' : ''" @click="setDialect(6)">Nederlands</span>
        <span class="dialect" :class="dialect === 5 ? 'active' : ''" @click="setDialect(5)">English</span>
        <span class="dialect" :class="dialect === 7 ? 'active' : ''" @click="setDialect(7)">Credits</span>
      </p>

      <div v-if="dialect === 0">
        <p>Och kiek dij lammer nou toch lurken<br>
          Aan deur krekt schoren moekes<br>
          En hou dij jonge swoanen, dobbern in zaachte sloot<br>
          En hou swoule wind<br>
          De wolken waait tot pas wossen luchten</p>

        <p>En kiek dij vooltjes nou toch wankeln<br>
          En de vogels noar heur nusten<br>
          En hou swoule wind de wolken waait<br>
          Tot pas wossen luchten</p>

        <p>Och ik ben zo vereerd<br>
          Dus van nijs<br>
          ’n jong veur-joar weerd</p>

        <p>Ken wat mooier as dat ’t mooi is<br>
          Ken wat groter den as groot</p>

        <p>Om te janken zo mooi.</p>
      </div>

      <div v-if="dialect === 1">
        <p>Ach kiek dee lämmekes noe is lörk&lsquo;n<br>
          An eare net geschoar&lsquo;n moors<br>
          En hoo dee joonke zwaan&lsquo;n, dukelt in den zachten graamn<br>
          En hoo den zoel’n weend<br>
          De wolken wea’jt tot pas gewass&lsquo;n löcht&lsquo;n</p>

        <p>En kiek de vullen noe toch waankel&lsquo;n<br>
          En dee vöggel noar eare nust&lsquo;n<br>
          En hoo den zoel’n weend<br>
          De wolken wei’jt tot pas gewass&lsquo;n löcht&lsquo;n</p>

        <p>Ach ik bin zo vereard<br>
          Dus nog nen moal<br>
          Nen joonk veurjoar weerd</p>

        <p>Kan wat möjjer as’t à möj is<br>
          Kan wat groter wean as groot</p>

        <p>Um te jaanken zo mooi</p>
      </div>

      <div v-if="dialect === 2">
        <p>Och kiek de lammegies non toch lurken<br>
          An heur pas gescheuren mammes<br>
          En hoe de jonge zwanen, dobbert in de zachte sloot<br>
          En hoe de zwoele wind<br>
          De wolken weit, tot pasgewassen luchten</p>

        <p>En kiek de vullegies non toch wankeln<br>
          En de vogels naar heur nusten<br>
          Hoe de zwoele wind de wolken weit<br>
          Tot pasgewassen luchten</p>

        <p>Och ik bin zo vereerd<br>
          Dus nog ienmaol<br>
          Een jonge meitiet weerd</p>

        <p>Kan het mooier as het mooi is<br>
          Kan wat groter weden dan groot</p>

        <p>Um te reren zo mooi</p>
      </div>

      <div v-if="dialect === 3">
        <p>Och kiek de lammetjes toch lurken<br>
          An derluiers vurs eskeuren moeders<br>
          In oe de jonge zwoanen, donzen in de zachte sloot<br>
          In oe de warme wient<br>
          De wolleken bloast, tot pas ewossen lochten</p>

        <p>An kiek die veulens toch nou wankelen<br>
          In de voegels nor der luiers nest<br>
          In oe de warme wient de wolleken bloast<br>
          Tot pas ewossen lochten</p>

        <p>Och ik bin ze vereerd<br>
          Dus nog een keer<br>
          Een jonge linte waard</p>

        <p>Kan iets mooier dan ut mooi is<br>
          Kan iets grooter wezen dan groot</p>

        <p>Om te kreten zo mooi</p>
      </div>

      <div v-if="dialect === 4">
        <p>Ach zie de lammegies now toch lurken<br>
          An hun vas gescheuren mammes<br>
          En hoe de jonge zwaenen daansen in de natte sloot<br>
          En hoe de waarme wiend<br>
          De wolken wi&lsquo;'jt tot mooie schonere lochten</p>

        <p>En zie de veultjes now es waankel lopen<br>
          En de voegels naor heur nusten zuken<br>
          En hoe de waarme wiend de wolken wi'jt<br>
          Tot mooie schonere lochten </p>

        <p>Zo vereerd bin ik<br>
          Dus nog ien keer<br>
          Een jonge lente weerd </p>

        <p>Kan iets mooier dan et mooi is<br>
          Kan iets groter wezen dan groot</p>

        <p>Om te jaanken zo mooi</p>
      </div>

      <div v-if="dialect === 5">
        <p>
          Well see the lambs gulping anyway<br>
          To their freshly shaved mothers<br>
          And how the young swans<br>
          Bathing down in the canal
        </p>

        <p>
          And how the balmy wind blows the clouds<br>
          Into newly washed air
        </p>

        <p>
          And see the foals falter anyway<br>
          And the birds to their nests
        </p>

        <p>
          And how the balmy wind blows the clouds<br>
          Into newly washed air
        </p>

        <p>
          I am so honored<br>
          So, I am again<br>
          Worth another young spring
        </p>

        <p>
          Can it be slightly more beautiful than beautiful<br>
          Can it be slightly larger than large
        </p>

        <p>
          Bellowing at its beauty
        </p>
      </div>
      <div v-if="dialect === 6">
        <p>Ach zie de lammeren nou toch lurken<br>
          Aan hun vers geschoren moeders<br>
          En hoe de jonge zwanen<br>
          Donzen in de zachte sloot</p>

        <p>En hoe de zwoele wind de wolken waait<br>
          Tot pas gewassen luchten</p>

        <p>En zie de veulens nou toch wankelen<br>
          En de vogels naar hun nesten</p>

        <p>En hoe de zwoele wind de wolken waait<br>
          Tot pas gewassen luchten</p>

        <p>Ach ik ben zo vereerd<br>
          Dus nog een keer<br>
          Een jonge lente waard</p>

        <p>Kan iets mooier zijn dan het mooi is<br>
          Kan iets groter zijn dan groot<br>
          Om te janken zo mooi</p>
      </div>
      <div v-if="dialect === 7">
        <p>Production:
           Composer and main conductor - Isidor ten Hooven<br>
           Installation engineer - Giovanni Salice<br>
           Cinematographer - Lester Kamstra<br>
           Pianist and conducting support - Ruben Stolp</p>

        <p>Translators:
          Drents - Willy Weerman<br>
          Gronings - Herman Loning<br>
          Twents - Herman Telgenkamp &amp; Angela Siegersma<br>
          Stellingwerfs - Reitze Brouwer<br>
          Urkers - Douwe Gnodde</p>

        <p>Singers:
          Drente - Janny Veltman<br>
          Twente - Herman Telgenkamp<br>
          Groningen - Trijnie Groeneveld<br>
          Stellingwerf - Reitze Brouwer<br>
          Urk - Douwe Gnodde</p>

        <p>The song is inspired by Maarten van Roozendaal with his song ‘Mooi’</p>

        <p>Tune of the Hamlets is directed by Daniël Siegersma </p>

        <p>This project is created in collaboration with Tetem.</p>
      </div>
      <div class="mixer-credits" v-if="playing">
        Tune Of The Hamlets<br>Dani&euml;l Siegersma
      </div>
    </div>

  </div>
</template>
<script>
import Playable from './Playable'

export default {
  name: 'Mixer',
  data() {
    return {
      dialect: 1,
      twents: 75,
      drents: 75,
      urkers: 75,
      gronings: 75,
      stellingwerfs: 75,
      piano: 75,
      playing: false
    }
  },
  methods: {
    setDialect(value) {
      this.dialect = value
    },
    startMixer(event) {
      this.$emit('start-mixer', true)
      setTimeout(this.stopMixer, 1000)
    },
    stopMixer(event) {
      this.$emit('stop-mixer', true)
    },
    togglePlayback(willPlay) {
      this.playing = willPlay
      this.$refs.video.volume = 0
      this.$refs.video[willPlay ? 'play' : 'pause']()
      this.$refs.twents[willPlay ? 'play' : 'pause']()
      this.$refs.drents[willPlay ? 'play' : 'pause']()
      this.$refs.urkers[willPlay ? 'play' : 'pause']()
      this.$refs.gronings[willPlay ? 'play' : 'pause']()
      this.$refs.stellingwerfs[willPlay ? 'play' : 'pause']()
      this.$refs.piano[willPlay ? 'play' : 'pause']()
    }
  },
  watch: {
    twents(newValue) {
      this.$refs.twents.volume = newValue / 100
    },
    drents(newValue) {
      this.$refs.drents.volume = newValue / 100
    },
    urkers(newValue) {
      this.$refs.urkers.volume = newValue / 100
    },
    gronings(newValue) {
      this.$refs.gronings.volume = newValue / 100
    },
    stellingwerfs(newValue) {
      this.$refs.stellingwerfs.volume = newValue / 100
    },
    piano(newValue) {
      this.$refs.piano.volume = newValue / 100
    }
  },
  components: {
    Playable
  }
}
</script>
<style>
  .choir {
    display: flex;
  }
  .lyrics {
    width: 50%;
    margin-left: 2rem;
  }
  .lyrics div {
    width: 90%;
  }
  .mixer {
    width: 100%;
  }
  .mixer-video {
    width: 100%;
  }
  .mixer-video video {
    width: 100%;
  }
  .mixer-buttons {
    display: flex;
    width: 100%;
    padding: 1rem;
    align-items: center;
    justify-content: center;
    z-index: 2000;
  }
  .mixer-button {
    text-align: center;
    margin: 0 0.5rem;
  }
  .dialect {
    cursor: pointer;
    text-decoration: underline;
  }
  .dialect.active {
    text-decoration: none;
  }
  .mixer-credits {
    position: absolute;
    bottom: 0;
  }
</style>
