<template>
  <div class="playable">
    <div class="playable-overlay" @click="toggle" v-if="(once && !triggered) || !once" :class="(triggered || playing) ? 'hidden' : ''">
      <div class="playable-overlay-caption">
        {{ caption }}<br>{{ artist }}
      </div>
    </div>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'Playable',
  props: ['caption', 'artist', 'once', 'playing'],
  data() {
    return {
      triggered: false
    }
  },
  methods: {
    toggle() {
      this.triggered = this.$props.once ? true : !this.triggered
      this.$emit('triggered', this.triggered)
    }
  }
}
</script>
<style>
.playable {
  width: 100%;
  height: 100%;
  position: relative;
}
.playable-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d1d1d1;
  cursor: pointer;
  z-index: 5000;
  pointer-events: all;
}
.playable-overlay.hidden {
  opacity: 0;
}
.playable-overlay-caption {
  text-transform: uppercase;
  color: #ff5c00;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 400;
}
</style>
