<template>
  <div class="davide-karaoke">
    <div class="davide-karaoke-player">
      <Playable :once="true" :caption="'Neiderlant Ou Neiderlant'" :artist="'Davide Tidoni'" ref="faderliff" :playing="playing" @triggered="setPlayback">
        <iframe title="vimeo-player" :src="videoSource" :key="'iframe-' + videoSource" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
      </Playable>
    </div>
    <div class="timecode-links">
      <span class="timecode" :class="currentVideo === 0 ? 'active' : ''" @click="setVideo(0)">A FADER LIFF</span>
      <span class="timecode" :class="currentVideo === 1 ? 'active' : ''" @click="setVideo(1)">NEIDERLANT</span>
      <span class="timecode" :class="currentVideo === 2 ? 'active' : ''" @click="setVideo(2)">PALOMA</span>
      <span class="timecode" :class="currentVideo === 3 ? 'active' : ''" @click="setVideo(3)">MOI MAN</span>
      <span class="timecode" :class="currentVideo === 4 ? 'active' : ''" @click="setVideo(4)">PIZZA</span>
      <div class="davide-credits" v-if="playing">
        Neiderlant Ou Neiderlant<br>Davide Tidoni
      </div>
    </div>
  </div>
</template>
<script>
import Playable from './Playable'

export default {
  name: 'DavideKaraoke',
  data() {
    return {
      currentVideo: 0,
      playing: false
    }
  },
  computed: {
    videoSource() {
      const timecodes = [
        '0m3s',
        '3m7s',
        '5m55s',
        '9m17s',
        '13m0s'
      ]

      return `https://player.vimeo.com/video/422562546?title=0&byline=0&portrait=0&sidedock=0&controls=1&color=FF5C00&autoplay=${this.playing ? 1 : 0}#t=${timecodes[this.currentVideo]}`
    }
  },
  methods: {
    setVideo(video) {
      this.playing = true
      this.currentVideo = video
    },
    setPlayback() {
      this.playing = true
    }
  },
  components: {
    Playable
  }
}
</script>
<style>
.davide-karaoke {
  width: 100%;
  display: flex;
}
.davide-karaoke-player {
  width: 666px;
  height: 500px;
  background: rgba(0,0,0,0.1);
}
.timecode-links {
  padding-left: 2rem;
}
.timecode {
  display: block;
  cursor: pointer;
  width: 100%;
  text-decoration: underline;
}
.timecode.active {
  text-decoration: none;
}
.davide-credits {
  position: absolute;
  bottom: 0;
}
</style>
