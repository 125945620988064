<template>
  <div class="client" :style="style">
    <div class="client-balloon" v-if="client.name || client.message">
      <p class="name">
        {{ client.name }}
      </p>
      <p v-if="client.message" class="message">
        {{ client.message }}
      </p>
    </div>
  </div>
</template>
<script>
import images from '../public/avatars/*.png'

export default {
  name: 'Client',
  props: ['client'],
  computed: {
    style () {
      if (!this.$props.client.position) {
        return {}
      }
      return {
        transform: `translate(${this.$props.client.position.x}px, ${this.$props.client.position.y}px)`,
        backgroundImage: `url('${images[this.$props.client.avatar]}')`
      }
    }
  }
}
</script>
<style>
.client {
  position: fixed;
  top: 0;
  left: 0;
  width: 20rem;
  height: 80px;
  margin: -40px 0 0 -40px;
  background-position: left;
  background-size: contain;
  background-repeat: no-repeat;
  font-weight: 300;
  text-align: center;
  padding: 1rem 0;
  z-index: 5000;
}
.client-balloon {
  position: absolute;
  top: 10px;
  left: 90px;
  text-align: left;
  background: #5b5b5b;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
}
.client-balloon p {
  margin: 0.25rem 0;
}
.name,
.message {
  font-size: 10pt;
  text-align: left;
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  color: #fff;
  width: 100%;
}
.name {
  font-weight: bold;
}
.message {
  margin-bottom: 1rem;
}
</style>
