<template>
  <div :style="style" class="sticker">
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: 'Sticker',
  props: ['x', 'y', 'width', 'height'],
  computed: {
    style () {
      return {
        transform: `translate(${this.x}, ${this.y})`,
        width: this.width,
        height: this.height
      }
    }
  }
}
</script>
<style>
.sticker {
  position: absolute;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
}
.title {
  font-size: 490px;
  text-transform: uppercase;
  user-select: none;
  color: #d1d1d1;
  pointer-events: none;
  height: 100%;
}
.title img {
  margin-top: -10px;
  height: 524px;
}
.background {
  width: 100%;
  height: 100%;
  background: #d1d1d1
}
</style>
