<template>
  <div class="matteo-karaoke">
    <div class="matteo-karaoke-player">
      <Playable :caption="'Strangers in Karaoke'" :artist="'Matteo Marangoni'" ref="karaoke" :playing="playing" @triggered="togglePlayback" :once="true">
        <iframe title="vimeo-player" :src="videoSource" :key="'iframe-' + videoSource" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
      </Playable>
    </div>
    <div class="karaoke-title-links">
      <span class="karaoke-title" :class="currentVideo === 0 ? 'active' : ''" @click="setVideo(0)"><u>Matteo Marangoni</u> sings Nel Blu Dipinto di Blu by Domenico Modugno</span>
      <span class="karaoke-title" :class="currentVideo === 1 ? 'active' : ''" @click="setVideo(1)"><u>Wen Chin Fu</u> sings You Are My Flower by Wu Bai &amp; China Blue</span>
      <span class="karaoke-title" :class="currentVideo === 2 ? 'active' : ''" @click="setVideo(2)"><u>Ludmila Rodrigues</u> sings Lambada by Kaoma</span>
      <span class="karaoke-title" :class="currentVideo === 3 ? 'active' : ''" @click="setVideo(3)"><u>Jonathan Reus</u> sings Nine to Five by Dolly Parton</span>
      <span class="karaoke-title" :class="currentVideo === 4 ? 'active' : ''" @click="setVideo(4)"><u>Erfan Abdi</u> sings Soltane Ghalbha by Aref</span>
      <span class="karaoke-title" :class="currentVideo === 5 ? 'active' : ''" @click="setVideo(5)"><u>Philip Vermeulen</u> sings Kom Van Dat Dak Af by Peter Koelewijn</span>
      <span class="karaoke-title" :class="currentVideo === 6 ? 'active' : ''" @click="setVideo(6)"><u>Ji Youn Kang</u> sings You Inside My Dim Memory by Hyun Jin Young</span>
      <span class="karaoke-title" :class="currentVideo === 7 ? 'active' : ''" @click="setVideo(7)"><u>Budhaditya Chattopadhyay</u> sings Chirodini Tumi Je Aamar by Kishore Kumar</span>
      <span class="karaoke-title" :class="currentVideo === 8 ? 'active' : ''" @click="setVideo(8)"><u>Sissel Marie Tonn</u> sings Susanne, Birgitte og Hanne by Otto Brandenburg</span>

      <div class="matteo-credits" v-if="playing">
        Strangers in Karaoke<br>Matteo Marangoni
      </div>
    </div>
  </div>
</template>
<script>
import Playable from './Playable'

export default {
  name: 'MatteoKaraoke',
  data() {
    return {
      currentVideo: 0,
      playing: false
    }
  },
  computed: {
    videoSource() {
      const embeds = [
        '372961963',
        '413891816',
        '371186422',
        '371185132',
        '413892063',
        '371193732',
        '371180234',
        '371355522',
        '371195568'
      ]

      return `https://player.vimeo.com/video/${embeds[this.currentVideo]}?title=0&byline=0&portrait=0&sidedock=0&color=FF5C00&controls=1&autoplay=${this.playing ? 1 : 0}`
    }
  },
  methods: {
    setVideo(video) {
      this.playing = true
      this.currentVideo = video
    },
    togglePlayback(willPlay) {
      this.playing = willPlay
    }
  },
  components: {
    Playable
  }
}
</script>
<style>
.matteo-karaoke {
  width: 100%;
  display: flex;
}
.matteo-karaoke-player {
  position: relative;
  width: 888px;
  height: 500px;
  background: rgba(0,0,0,0.1);
}
.karaoke-title-links {
  padding-left: 2rem;
}
.karaoke-title {
  display: block;
  cursor: pointer;
  width: 100%;
  margin-bottom: 0.1rem;
}
.karaoke-title.active u {
  text-decoration: none;
}
.matteo-credits {
  position: absolute;
  bottom: 0;
}
</style>
