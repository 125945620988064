<template>
  <div @mousedown="startDrag" @mouseup="stopDrag" @mousemove="moveAt" @touchstart="startTouchDrag" @touchmove="touchMoveAt" id="viewport">
    <div id="canvas" v-bind:style="style">
      <slot></slot>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DraggableCanvas',
  props: ['draggable'],
  data() {
    return {
      currentX: 0,
      currentY: 0,
      dragX: 0,
      dragY: 0,
      dragging: false
    }
  },
  computed: {
    style () {
      return {
        transform: `translate(${this.currentX}px, ${this.currentY}px)`
      }
    }
  },
  methods: {
    startDrag(event) {
      if (!this.$props.draggable) return
      this.dragging = true
      this.dragX = event.x
      this.dragY = event.y
    },
    stopDrag(event) {
      this.dragging = false
    },
    moveAt(event) {
      if (!this.dragging) {
        return
      }

      const movement = {
        x: event.x - this.dragX,
        y: event.y - this.dragY
      }

      this.currentX += movement.x
      this.currentY += movement.y
      this.dragX = event.x
      this.dragY = event.y

      this.$emit('dragged', {
        x: -1 * this.currentX,
        y: -1 * this.currentY
      })
    },
    startTouchDrag(event) {
      const { pageX, pageY } = event.touches[0]
      this.startDrag({ x: pageX, y: pageY })
    },
    touchMoveAt(event) {
      const { pageX, pageY } = event.touches[0]
      this.moveAt({ x: pageX, y: pageY })
    }
  },
  mounted() {
    this.$emit('dragged', {
      x: 0,
      y: 0
    })
    document.body.addEventListener('mouseleave', this.stopDrag)
  }
}
</script>
<style>
#viewport {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
#canvas {
  width: 100%;
  height: 100%;
  cursor: move;
  font-size: 10pt;
}
</style>
