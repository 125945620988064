<template>
  <div class="research">
    <div class="research-player">
      <Playable :once="true" :caption="'Don\'t Ask Me Where I\'m From'" :artist="'Research Interviews'" :playing="playing" @triggered="togglePlayback">
        <iframe title="vimeo-player" :src="videoSource" :key="'iframe-' + videoSource" width="888px" height="500px" frameborder="0" allowfullscreen></iframe>
      </Playable>
    </div>
    <div class="research-caption" v-if="playing">
      Don't Ask Me Where I'm From<br>Research Interviews
    </div>
  </div>
</template>
<script>
import Playable from './Playable'

export default {
  name: 'Research',
  data() {
    return {
      playing: false
    }
  },
  computed: {
    videoSource() {
      return `https://player.vimeo.com/video/372842917?title=0&byline=0&portrait=0&sidedock=0&color=FF5C00&autoplay=${this.playing ? 1 : 0}`
    }
  },
  methods: {
    togglePlayback(willPlay) {
      this.playing = willPlay
    }
  },
  components: {
    Playable
  }
}
</script>
<style>
.research {
  display: flex;
  position: relative;
}
.research-player {
  width: 888px;
}
.research-caption {
  width: 200px;
  position: absolute;
  bottom: 0;
  right: 0;
  padding-left: 1rem;
}
</style>
