<template>
  <div>
    <Counter :clients="Object.keys(clients).length"></Counter>
    <DraggableCanvas @dragged="getCoords" :draggable="draggable">
      <div v-for="client in clients" :key="client.clientId">
        <Client v-if="client.clientId !== socketId" :client="client"></Client>
      </div>
      <Sticker x="0" y="0" width="2000px" height="500px">
        <div class="title"><img src="../public/type/should.svg" alt="Should"></div>
      </Sticker>

      <!-- Colophon -->
      <Sticker x="2600px" y="0px" width="2050px" height="500px">
        <Colophon></Colophon>
      </Sticker>

      <!-- Matteo's Karaoke -->
      <Sticker x="1100px" y="530px" width="1500px" height="500px">
        <MatteoKaraoke></MatteoKaraoke>
      </Sticker>
      <Sticker x="2650px" y="530px" width="1500px" height="500px">
        <div class="title"><img src="../public/type/istay.svg" alt="I Stay"></div>
      </Sticker>

      <!-- Choir -->
      <Sticker x="0" y="1060px" width="1350px" height="500px">
        <Mixer @start-mixer="lockDrag" @stop-mixer="unlockDrag"></Mixer>
      </Sticker>
      <Sticker x="1300px" y="1060px" width="20px" height="500px">
        <div class="title"><img src="../public/type/or.svg" alt="Or"></div>
      </Sticker>

      <!-- Research -->
      <Sticker x="2550px" y="1060px" width="1088px" height="500px">
        <Research></Research>
      </Sticker>

      <Sticker x="2000px" y="1590px" width="20px" height="500px">
        <div class="title"><img src="../public/type/should.svg" alt="Should"></div>
      </Sticker>
      <Sticker x="0" y="2130px" width="1200px" height="500px">
        <div class="title"><img src="../public/type/igo.svg" alt="I Go"></div>
      </Sticker>

      <!-- Survey -->
      <Sticker x="1300px" y="2130px" width="1400px" height="500px">
        <Survey></Survey>
      </Sticker>

      <!-- Davide's Karaoke -->
      <Sticker x="3250px" y="2130px" width="1000px" height="500px">
        <DavideKaraoke></DavideKaraoke>
      </Sticker>
    </DraggableCanvas>

    <Avatar @set-name="setName" @set-message="setMessage" :avatar="avatar"></Avatar>

    <div class="warning" v-if="showWarning">
      Your screen is not the optimal size to view this website. Try making the browser wider or switch to a different device. <a href="#" @click="dismissWarning">Got it</a>
    </div>
  </div>
</template>

<script>
import Avatar from './Avatar'
import Client from './Client'
import Colophon from './Colophon'
import Counter from './Counter'
import DavideKaraoke from './DavideKaraoke'
import DraggableCanvas from './DraggableCanvas'
import Mixer from './Mixer'
import MatteoKaraoke from './MatteoKaraoke'
import Research from './Research'
import Sticker from './Sticker'
import Survey from './Survey'

export default {
  name: 'App',
  data() {
    return {
      draggable: true,
      socketId: null,
      avatar: 0,
      clients: {},
      showWarning: true
    }
  },
  methods: {
    getCoords(event) {
      const { x, y } = event
      window.socket.emit('set-position', {
        x: x + (window.innerWidth / 2) - 130,
        y: y + window.innerHeight - 120
      })
    },
    dismissWarning() {
      this.showWarning = false
    },
    setName(name) {
      window.socket.emit('set-name', name)
    },
    setMessage(message) {
      window.socket.emit('set-message', message)
    },
    lockDrag() {
      this.draggable = false
    },
    unlockDrag() {
      this.draggable = true
    }
  },
  components: {
    Avatar,
    Client,
    Colophon,
    Counter,
    DavideKaraoke,
    DraggableCanvas,
    MatteoKaraoke,
    Mixer,
    Research,
    Sticker,
    Survey
  },
  created() {
    window.socket.on('current-clients', clients => {
      this.clients = clients
      this.socketId = window.socket.id
      this.avatar = clients[this.socketId].avatar
    })

    window.socket.on('new-client', client => {
      this.clients[client.clientId] = client
      this.$forceUpdate()
    })

    window.socket.on('position-set', client => {
      this.clients[client.clientId] = client
      this.$forceUpdate()
    })

    window.socket.on('message-set', client => {
      this.clients[client.clientId] = client
      this.$forceUpdate()
    })

    window.socket.on('name-set', client => {
      this.clients[client.clientId] = client
      this.$forceUpdate()
    })

    window.socket.on('disconnect', clients => {
      this.clients = clients
    })
  }
}
</script>
<style>
.warning {
  display: none;
}
@media (max-width: 900px) {
  .warning {
    display: block;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 1rem;
    font-size: 10pt;
    background: #000;
    color: #ff5c00;
    line-height: 1.3;
  }
  .warning a {
    color: #ff5c00;
  }
}
</style>
