<template>
  <div class="colophon">
    <div class="colophon-text">
      <p><span class="colophon-tab" :class="tab === 0 ? 'active' : ''" @click="setTab(0)">Should I Stay Or Should I Go</span> &middot; <span class="colophon-tab" :class="tab === 1 ? 'active' : ''" @click="setTab(1)">Work descriptions</span></p>

      <span v-if="tab === 0">
        <p>  In the hit single from 1981 Mick Jones of UK band The Clash infects us with an earworm: <br>“If I go there will be trouble, if I stay it will be double”. Is the song really about an unhappy love story, or is the question pressed by the punk rocker hinting to something else?</p>

        <p>This exhibition starts as an anthropological investigation and ends in a series of playful rituals engaging with our senses through song and food. Questions around identity and migration<br>are viewed through the individual experiences of members of the international artist community<br>in The Hague. Tensions experienced while living in transition between places and cultures serve as fuel for a series of new works.  </p>

        <p>Should I Stay Or Should I Go is a project commissioned by <a href="https://tetem.nl" target="_blank">Tetem</a> and developed with the participation of: Erfan Abdi, Marije Baalman, Budhaditya Chattopadhyay, Wen Chin Fu, Ji Kang, Gábor Kerekes, Lars Kynde, Sebastiaan van ‘t Holt, Hanna Ijäs, Matteo Marangoni, Marit Mihklepp, Farah Rahman, Flora Reznik,  Jonathan Reus, Ludmila Rodrigues, Daniël Siegersma, Davide Tidoni, Gabey Tjon a Tham, Sissel Marie Tonn and Philip Vermeulen. </p>

        <p>Tune of the Hamlets by Daniël Siegersma is realised with: Giovanni Salice, Isidor ten Hooven, Lester Kamstra, Willy Weerman, Herman Loning, Angela Siegersma, Reitze Brouwer, Douwe Gnodde, Herman Telgenkamp, Janny Veltman and Trijnie Groeneveld.</p>

        <p>This online exhibition is presented by <a href="https://instrumentinventors.org/" target="_blank">iii</a> in collaboration with <a href="https://www.quartair.nl/" target="_blank">Quartair</a> with financial support from <a href="https://www.denhaag.nl/" target="_blank">The Municipality of The Hague</a>, <a href="https://stroom.nl/" target="_blank">Stroom Den Haag</a> and <a href="https://stimuleringsfonds.nl/en/" target="_blank">The Creative Industries Fund NL</a>.</p>

        <p>Online exhibition design:<br><a href="https://instagram.com/jean_baptiste_castel" target="_blank">Jean-Baptiste Castel</a>, <a href="https://nuel.work" target="_blank">nuel van gelder de neufville</a>  </p>
      </span>
      <span v-if="tab === 1">
        <p>Matteo Marangoni &amp; Davide Tidoni - Strangers in Karaoke and Neiderlant Ou Neiderlant</p>

        <p>Davide Tidoni and Matteo Marangoni address the challenge of becoming familiar with a foreign culture while confronting negative stereotypes. Davide Tidoni in Neiderlant Ou Neiderlant asked non-native Dutch residents to make karaoke interpretations of popular Dutch songs. The Dutch songs have been transcribed by the non native speakers according to the phonetics of their native tongue. Matteo Marangoni in Strangers in Karaoke  instead asked project participants to choose a cliché song from their country of origin. Exhibition visitors are invited to sing along. </p>


        <p>Daniël Siegersma - Tune of the Hamlets</p>

        <p>In Tune of the Hamlets Daniël Siegersma channels his attachment to the native language of his ancestors by joining in song the different dialects of Low Saxon. The artist formed a chorus of 5 singers, each coming from a different region of The Netherlands where a different dialect of Low Saxon is spoken: Urkers, Gronings, Twents, Drents and Stellingwerfs. The 5 singers were asked to sing the same song together, but each in their own dialect. The song is presented as an interactive video that allows visitors to listen and compare the differences between the regional dialects.</p>


        <p>Wen Chin Fu - Nederlands Gastronomisch Inburgeringsexamen</p>

        <p>With Nederlands Gastronomisch Inburgeringsexamen Wen Chin Fu has imagined an extension to the examination process for becoming a Dutch Citizen. In Taiwan, where the artist was born, sharing food is the primary way of being together. Recently Wen Chin Fu obtained her “inburgeringsdiploma”. She was surprised to find that in the exam over Dutch Society there were no questions at all about food culture.  Wen Chin designed a new inburgerings questionnaire around food culture in The Netherlands, where exhibition visitors can find out if their eating habits are qualified for equal citizenship rights.</p>
      </span>
    </div>
    <div class="livestream">
      <Playable :caption="'Live stream'" :once="true">
        <iframe src="https://player.twitch.tv/?channel=staygokaraokeparty&parent=stay-go.nl" width="888px" height="500px" style="border:0"></iframe>
      </Playable>
    </div>
    <div>
      <p>Live events:</p>

      <p>Online Karaoke party with Matteo Marangoni and Davide Tidoni<br>
        Friday May 29 2020 <br>
        21:00—23:00 CEST</p>

      <p>Online cooking workshop with Wen Chin Fu<br>
        Wednesday 10 June 2020<br>
 19:00 CEST<br>
        <a href="https://docs.google.com/forms/d/e/1FAIpQLScOskGMe04RlQRVdAGkDvHEiwxDX_XMW52S6gV30lMPo5QmNg/viewform" target="_blank">Sign up here</a></p>

      <p>
        Join our Zoom <a href="https://stay-go.nl/zoom">here</a>
      </p>
    </div>
  </div>
</template>
<script>
import Playable from './Playable'

export default {
  name: 'Colophon',
  data() {
    return {
      tab: 0
    }
  },
  computed: {
    streamSource() {
      return `https://www.youtube.com/embed/JEy-j43iEfA?controls=0&autoplay=${this.playing ? 1 : 0}`
    }
  },
  methods: {
    setTab(value) {
      this.tab = value
    }
  },
  components: {
    Playable
  }
}
</script>
<style>
.colophon div {
  float: left;
  margin-right: 2rem;
}
.colophon .colophon-text {
  width: 650px;
  font-size: 10pt;
  line-height: 1.3;
  padding-right: 5rem;
}

.colophon a {
  color: #000;
}
.colophon-tab {
  cursor: pointer;
  text-decoration: underline;
}
.colophon-tab.active {
  text-decoration: none;
}
</style>
