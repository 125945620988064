<template>
  <div class="counter">
    <span class="counter-circle"></span>{{ clients }}
  </div>
</template>
<script>
export default {
  name: 'Counter',
  props: ['clients']
}
</script>
<style>
.counter {
  position: fixed;
  top: 1rem;
  right: 1rem;
  background: #5b5b5b;
  color: #fff;
  padding: 0.55rem 0.75rem 0.45rem;
  border-radius: 1rem;
  z-index: 5000;
}
.counter-circle {
  display: inline-block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 100%;
  background: #FF5C00;
  margin-right: 0.3rem;
}
</style>
