<template>
    <div class="survey-container">
      <a href="https://testmoz.com/q/2330653" target="_blank" class="playable">
        <div class="playable-overlay">
          <div class="playable-overlay-caption">Nederlands Gastronomisch Inburgeringsexamen<br>Wen Chin Fu<br><span class="arrow-out">&nearr;</span></div>
        </div>
      </a>
      <div class="survey-results">
        <span class="survey-results-caption">% answered correctly</span>
        <p><span class="result"><span class="result-bar" style="width:94%"></span> 94%</span>Where do you come from?</p>
        <p><span class="result"><span class="result-bar" style="width:0%"></span> 0%</span>Which is your favorite cuisine?</p>
        <p><span class="result"><span class="result-bar" style="width:0%"></span> 0%</span>Which is your favorite Dutch food?</p>
        <p><span class="result"><span class="result-bar" style="width:82%"></span> 82%</span>Which one is not Dutch food?</p>
        <p><span class="result"><span class="result-bar" style="width:11%"></span> 11%</span>How many "boterhammen" do you eat everyday?</p>
        <p><span class="result"><span class="result-bar" style="width:72%"></span> 72%</span>Where is the name “kapsalon“ coming from?</p>
        <p><span class="result"><span class="result-bar" style="width:42%"></span> 42%</span>Do you make a sound while eating?</p>
        <p><span class="result"><span class="result-bar" style="width:58%"></span> 58%</span>Where can you find "eierbal"?</p>
        <p><span class="result"><span class="result-bar" style="width:77%"></span> 77%</span>Which one is not a Dutch dessert?</p>
        <p><span class="result"><span class="result-bar" style="width:68%"></span> 68%</span>Which ingredient is not included in "Snert"?</p>
        <p><span class="result"><span class="result-bar" style="width:76%"></span> 76%</span>What is Dorp?</p>
        <p><span class="result"><span class="result-bar" style="width:83%"></span> 83%</span>What is "De schijf van vijf"?</p>
        <p><span class="result"><span class="result-bar" style="width:64%"></span> 64%</span>Do you have a regular eating schedule?</p>
        <p><span class="result"><span class="result-bar" style="width:45%"></span> 45%</span>Do you eat and walk at the same time in the public?</p>
        <p><span class="result"><span class="result-bar" style="width:84%"></span> 84%</span>How do you eat Dutch herring?</p>
        <p><span class="result"><span class="result-bar" style="width:8%"></span> 8%</span>Where was Liquorice(Drop) invented?</p>
        <p><span class="result"><span class="result-bar" style="width:64%"></span> 64%</span>Drop is juice extract from which roots?</p>
        <p><span class="result"><span class="result-bar" style="width:30%"></span> 30%</span>How do you decide your daily meal?</p>
        <p><span class="result"><span class="result-bar" style="width:24%"></span> 24%</span>When you follow instructions of cooking recipes, do you taste the ingredients in between?</p>
        <p><span class="result"><span class="result-bar" style="width:32%"></span> 32%</span>How many times do you eat "aardappels, vlees, groente" in a week?</p>
        <p><span class="result"><span class="result-bar" style="width:86%"></span> 86%</span>What time do you usually have your dinner?</p>
        <p><span class="result"><span class="result-bar" style="width:63%"></span> 63%</span>Which cuisine doesn’t have any Dutch influence?</p>
        <p><span class="result"><span class="result-bar" style="width:73%"></span> 73%</span>When is "Borreltijd"?</p>
        <p><span class="result"><span class="result-bar" style="width:10%"></span> 10%</span>How many types of drop are there in Holland?</p>
        <p><span class="result"><span class="result-bar" style="width:35%"></span> 35%</span>What is not a traditional snack for Oud&amp;Nieuw?</p>
        <p><span class="result"><span class="result-bar" style="width:59%"></span> 59%</span>"Vlaflip” or "broodpap", which one doesn’t contain yoghurt?</p>
        <p><span class="result"><span class="result-bar" style="width:26%"></span> 26%</span>When was "De schijf van vijf" introduced in Holland?</p>
      </div>
    </div>
</template>
<script>
export default {
  name: 'Survey',
  data() {
    return {

    }
  }
}
</script>
<style>
.survey-container {
  display: flex;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.survey-container a {
  display: block;
  width: 100%;
}
.arrow-out {
  font-size: 0.9em;
}
.survey-results {
  width: 1700px;
  font-size: 10pt;
  margin-left: 3rem;
  line-height: 1;
}
.survey-results-caption {
  display: inline-block;
  margin: 0 0 1rem 120px;
  padding-left: 1rem;
}
.survey-results p {
  margin: 0 0 0.2rem;
}
.result {
  display: inline-block;
  width: 120px;
  position: relative;
  text-align: right;
  margin-right: 1rem;
  color: #d1d1d1;
}
.result-bar {
  position: absolute;
  top: 0.1rem;
  right: 2.5rem;
  height: 0.5rem;
  background: #d1d1d1;
  float: right;
}
</style>
