import io from 'socket.io-client'
import Vue from 'vue'
import VueCircleSlider from 'vue-circle-slider'
import App from './App'

window.socket = io('https://realtime.stay-go.nl')

Vue.use(VueCircleSlider)
;(() => new Vue({
  el: '#app',
  render: h => h(App)
}))()
