<template>
  <div class="avatar" :style="style">
    <div class="balloon">
      <p>
        <input type="text" v-model="name" class="name" placeholder="Type a name…" maxlength="100">
      </p>
      <p>
        <input type="text" v-model="message" class="message" placeholder="Type a message…" maxlength="100">
      </p>
    </div>
  </div>
</template>
<script>
import images from '../public/avatars/*.png'

export default {
  name: 'Avatar',
  props: ['avatar'],
  data() {
    return {
      name: '',
      message: ''
    }
  },
  watch: {
    name(value) {
      this.$emit('set-name', value)
    },
    message(value) {
      this.$emit('set-message', value)
    }
  },
  computed: {
    style() {
      return `background-image: url('${images[this.$props.avatar]}')`
    }
  }
}
</script>
<style>
.avatar {
  position: fixed;
  bottom: 80px;
  left: 50%;
  width: 80px;
  height: 80px;
  margin-left: -170px;
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 2.2rem;
  font-weight: 300;
  text-align: center;
  padding: 1.4rem 0;
}
.balloon {
  position: absolute;
  top: 15px;
  left: 90px;
  width: 15rem;
  text-align: left;
  background: #5b5b5b;
  color: #fff;
  padding: 0.75rem 1rem;
  border-radius: 1rem;
}
.balloon p {
  margin: 0;
  padding: 0;
  line-height: 0;
}
.balloon input {
  font-size: 10pt;
  text-align: left;
  margin: 0 0 2px;
  background: none;
  border: 0;
  color: #fff;
  width: 100%;
  user-select: all;
  -webkit-user-select: all;
}
.name {
  font-weight: bold;
}
.message {
  margin-bottom: 0.75rem;
}
</style>
